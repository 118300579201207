.news article:not(:first-child) .post__categories a{
  color:  lighten($primary, 15);
}

.fast__links .link svg rect{
  fill:rgba( $primary, .2);
}


.bg-primary,
.bg-secondary,
.bg-dark{
  &, h2{
    color: white;
    &::after{
      background: lighten($primary, 15);
    }
  }
}

.agenda {
  background-color: $secondary;
  h2{
    color: white;
    &::after{
      background-color:  lighten($primary, 15);
    }
  }
  .event__content .event__date {
    color: lighten($primary, 15);
    font-weight: bold;
  }
}

.header__site .search__bar input[type="submit"]{
  background: lighten($primary, 15);
}

.header__site .top__bar .dropdown>a.dropdown-toggle{
  color: $white;
}
.header__site .btn--search svg{
  stroke: white;
}

.header__site .contact__link .icon__contact{
  filter: invert(100%);
}

.post__categories a{
  color: white !important;
}