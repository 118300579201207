footer.footer__Site{
  background-color: $secondary;
  color: white;
  padding-top: 3rem;
  a{
    color: white;
    text-decoration: none;
  }
  h2{
    text-transform: uppercase;
    position: relative;
    &::after{
      content: "";
      display: block;
      height: 8px;
      width: 48px;
      background: lighten($primary, 15);
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 3rem;
    }
  }
  h3{
    text-transform: uppercase;

    font-family: $text_black;
    font-size: 18px;
  }
  .social__links{
    list-style: none;
    padding-left: 0;
    display: flex;
    gap: 1rem;
    a{
      width: 40px;
      height: 40px;
      border-radius: 20px;
      color: lighten($primary, 15);
      border:2px solid;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: all .3s ease;
      &:hover{
        background-color: lighten($primary, 15);
        color: white;
      }
    }
  }
  .footer__bottom{
    margin-top: 3rem;
    background-color: white;
    color: $dark;
    a{
      color: inherit;
      text-decoration: none;
      display: inline-block;
      &:hover{
        font-weight: bold;
      }
    }
    .nav{
      gap: 1rem;
      li{
        padding: 1rem;
      }
    }
  }
  .social__links{
    .icon-illiwap {
      background: url("../img/icon-illiwap-correncon.svg") left no-repeat;
      background-size: 40px;
      width: 20px;
      height: 20px;
    }
  }
}
